const mm = 10 // pixels per millimeter

const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
canvas.width = 40 * mm
canvas.height = 40 * mm
canvas.style.width = canvas.width + 'px'
canvas.style.width = canvas.height + 'px'
document.body.append(canvas)

const p = document.createElement('p')
document.body.append(p)

ctx.fillStyle = `#222`

function generateHolesInCircle (_rows, _base) {
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  let circles = [1]
  const u = _base
  for (let a = 1; a < _rows; a++) {
    circles.push(a * u)
  }

  // const circles = [1, 6, 12, 20, 24, 32, 36]
  // const circles = [1, 8, 16, 24, 32, 40]
  // const circles = [1, 6, 9, 12, 15, 18]
  // const circles = [1, 6, 10, 15, 24, 33, 40]
  // const circles = [2, 3, 5, 7, 11, 13, 17, 19] // prime numbers
  // let circles = [1]
  // const j = 7
  // for (let a = 0; a < 6; a++) {
  //   circles.push(Math.floor((circles[a] + Math.random() * 10) / j) * j)
  // }

  const holesTotalArea = Math.PI * Math.pow(20, 2) * 0.25 // 25% air
  const numberOfHoles = circles.reduce((acc, val) => acc + val)
  const holeArea = holesTotalArea / numberOfHoles
  const holeRadius = Math.round(Math.sqrt(holeArea / Math.PI) * 5) / 5
  const holeDiameter = holeRadius * 2

  p.innerHTML = `${numberOfHoles} trous<br/>⌀ ${holeDiameter} mm<br/>${Math.round(100 * (numberOfHoles * Math.PI * holeRadius ** 2) / (Math.PI * 20 ** 2))}% air`

  circles.forEach((n, r) => {
    const radius = r / (circles.length - 1) * (20 * mm - holeRadius * mm)

    // ctx.fillStyle = `hsl(${Math.random() * 360}, 100%, 50%)`
    for (let i = 0; i < n; i++) {
      let x = Math.sin((i / n) * Math.PI * 2) * radius + 20 * mm
      let y = Math.cos((i / n) * Math.PI * 2) * radius + 20 * mm

      ctx.beginPath()
      ctx.ellipse(x, y, holeRadius * mm, holeRadius * mm, 0, 0, Math.PI * 2)
      ctx.fill()
    }
  })
}

function generateHolesGrid (_side) {
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  const positions = [] as any

  for (let y = 0; y < _side; y++) {
    for (let x = 0; x < _side; x++) {
      const pos = {
        x: (x + 0.5) * 40 / _side,
        y: (y + 0.5) * 40 / _side
      }

      const isInsideCircle = (pos.x - 20) ** 2 + (pos.y - 20) ** 2 < 40 * mm

      if (isInsideCircle) positions.push(pos)
    }
  }

  const holesTotalArea = Math.PI * Math.pow(20, 2) * 0.25 // 25% air
  const numberOfHoles = positions.length
  const holeArea = holesTotalArea / numberOfHoles
  const holeRadius = Math.round(Math.sqrt(holeArea / Math.PI) * 5) / 5
  const holeDiameter = holeRadius * 2

  p.innerHTML = `${numberOfHoles} trous<br/>⌀ ${holeDiameter} mm<br/>${Math.round(100 * (numberOfHoles * Math.PI * holeRadius ** 2) / (Math.PI * 20 ** 2))}% air`

  positions.forEach(({x, y}) => {
    ctx.beginPath()
    ctx.ellipse(x * mm, y * mm, holeRadius * mm, holeRadius * mm, 0, 0, Math.PI * 2)
    ctx.fill()
  })
}

function generate () {
  if (Math.random() > 0.5) {
    const rows = Math.floor(Math.random() * 6 + 3)
    const base = Math.floor(Math.random() * 6 + 3)
    generateHolesInCircle(rows, base)
  } else {
    const side = Math.floor(Math.random() * 10 + 3)
    generateHolesGrid(side)
  }
}

generate()
window.addEventListener('click', generate)
